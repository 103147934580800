import { log } from "package:/utils";
import { onMounted, shallowRef } from "vue";

function useStorage<T>(key: string, storage?: Storage, defaultValue?: T | undefined) {
  let initial: T | undefined = defaultValue;

  try {
    const stored = storage?.getItem(key);
    if (stored) initial = JSON.parse(stored) as T;
  } catch (e) {
    log.warn("failed to parse stored value");
  }

  const value = shallowRef<T | undefined>(initial);

  const onChange = (e: StorageEvent) => {
    if (e.key !== key) return;

    try {
      const stored = storage?.getItem(key);
      value.value = stored ? (JSON.parse(stored) as T) : undefined;
    } catch (e) {
      log.warn("storage value is invalid", e);
    }
  };

  onMounted(() => {
    window.addEventListener("storage", onChange);
    return () => {
      window.removeEventListener("storage", onChange);
    };
  });

  return {
    get value() {
      return value.value;
    },
    set value(v: T) {
      if (v === undefined) {
        storage?.removeItem(key);
      } else {
        storage?.setItem(key, JSON.stringify(v));

        if (typeof window !== "undefined") {
          window.dispatchEvent(new StorageEvent("storage", { key }));
        }
      }
      value.value = v;
    },
  };
}

export function usePersistentState<T>(key: string, defaultValue?: T | undefined) {
  return useStorage(key, process.browser ? localStorage : undefined, defaultValue);
}

export function useSessionState<T>(key: string, defaultValue?: T | undefined) {
  return useStorage(key, process.browser ? sessionStorage : undefined, defaultValue);
}
