import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { CardLink } from "package:/components/modules/CardLink";
import { RouteCard } from "package:/components/modules/RouteCard";
import { DetailType, ROUTE_VIEW, distanceToString } from "package:/utils";
import type {
  AreaShortResource,
  RoundShortResource,
  TrailShortResource,
} from "@greentrails/api";
import type { ClassNameValue } from "tailwind-merge";
import { defineComponent, useLanguage, useTranslations } from "#imports";
import { Link } from "../elements/Link";
import { Slider } from "./Slider";

interface Props {
  area: AreaShortResource;
  class?: ClassNameValue;
  index: number;
  total: number;
}

export const FullscreenAreaTeaser = defineComponent(
  (props: Props) => {
    const t = useTranslations();
    const lang = useLanguage();
    const routes: Array<RoundShortResource | TrailShortResource> = [
      ...props.area.rounds,
      ...props.area.trails,
    ];

    return () => (
      <div class="h-full pt-16 text-white lg:pt-36">
        <MobileSafeArea class="flex h-full flex-col justify-between">
          <div class="content">
            <div class="col-span-12">
              <p class="mb-1 text-xs uppercase">{`${t("homepage.area.trailarea")} ${
                props.index
              } / ${props.total}`}</p>
              <Link
                to={{
                  name: "lang-map-area",
                  params: {
                    lang: lang.value,
                    area: props.area.slug,
                  },
                  query: {
                    view: ROUTE_VIEW.DETAIL,
                  },
                }}
              >
                <h2 class="text-4xl lg:text-6xl">{props.area.title}</h2>
              </Link>

              <div class="mt-5 flex narrow-sm:hidden">
                <div class="mr-7">
                  <p class="text-xs uppercase">{t("misc.tracks")}</p>
                  <p class="mt-2 whitespace-nowrap font-thin text-3xl lg:font-thin lg:text-5xl">
                    {props.area.rounds.length}
                  </p>
                </div>
                {props.area.distance && (
                  <div class="mr-7">
                    <p class="text-xs uppercase">{t("misc.totalDistance")}</p>
                    <p class="mt-2 whitespace-nowrap font-thin text-3xl lg:font-thin lg:text-5xl">
                      {distanceToString(props.area.distance)}
                    </p>
                  </div>
                )}
                {props.area.elevation_gain && (
                  <div class="mr-7">
                    <p class="text-xs uppercase">{t("misc.altitudeMetres")}</p>
                    <p class="mt-2 whitespace-nowrap font-thin text-3xl lg:font-thin lg:text-5xl">
                      {distanceToString(props.area.elevation_gain, true)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="col-span-12">
              <Slider class="mt-5 mb-20 lg:mb-12">
                {routes.map((detail) => (
                  <div
                    class="flex-none pr-2"
                    key={`fullscreen-area-teaser-item-${detail.slug}`}
                  >
                    <RouteCard
                      areaId={props.area.slug}
                      detail={detail}
                      variant={detail.type === DetailType.Trail ? "mediumDark" : "dark"}
                    />
                  </div>
                ))}
                <div class="flex-none">
                  <CardLink
                    to={{
                      name: "lang-map-area",
                      params: {
                        lang: lang.value,
                        area: props.area.slug,
                      },
                      query: {
                        view: ROUTE_VIEW.DETAIL,
                      },
                    }}
                    label={t("homepage.area.toTrailArea") || ""}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </MobileSafeArea>
      </div>
    );
  },
  {
    name: "FullscreenAreaTeaser",
    props: ["class", "area", "index", "total"],
  },
);
