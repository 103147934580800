import appstore from "assets:/appstore.svg?url";
import playstore from "assets:/playstore.svg?url";
import { Link } from "package:/components/elements/Link";
import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { Picture } from "package:/components/elements/Picture";
import { defineComponent, ref, useFlags, useTranslations } from "#imports";

export const FullscreenAppTeaser = defineComponent(
  () => {
    const t = useTranslations();
    const flags = useFlags();
    const videoReady = ref(false);

    return () => (
      <>
        <div class="absolute h-dvh w-full overflow-hidden bg-green-900 narrow-xs:h-full">
          <Picture
            class="absolute top-0 left-0 h-full w-1/2 after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-black after:opacity-40 after:content-['']"
            width={710}
            height={900}
            sizes="1024px lg:960px xxl:1280px"
            densities={"x1"}
            src="/storage/assets/static/home-app-teaser.png"
            imgClass="w-full h-full"
            imgAttrs={{ ariaHidden: true }}
          />
          <div class="absolute top-0 right-0 h-full w-1/2">
            <Picture
              class="absolute top-0 left-0 h-full w-full"
              width={718}
              height={883}
              densities={"x1"}
              sizes="1024px lg:960px xxl:1280px"
              src="/storage/assets/static/home-intro-background.png"
              imgClass="w-full h-full"
              imgAttrs={{
                ariaHidden: true,
              }}
            />
            <Picture
              class={[
                "-translate-y-1/2 -translate-x-1/2 absolute top-1/2 left-1/2 w-[60%] min-w-[200px] max-w-[320px] shadow-2xl shadow-green-800",
                !videoReady.value && "opacity-0",
              ]}
              width={831}
              height={1490}
              densities={"x1"}
              sizes="640px"
              src="/storage/assets/static/app-shot.png"
              imgAttrs={{ ariaHidden: true }}
            />
            <div class="-translate-y-1/2 -translate-x-1/2 absolute top-1/2 left-1/2 w-[60%] min-w-[200px] max-w-[320px]">
              <video
                onTimeupdate={() => {
                  videoReady.value = true;
                }}
                autoplay
                class="h-full w-full object-cover"
                muted
                playsinline
              >
                <source src="/intro/intro.mov" type="video/quicktime" />
                <source src="/intro/intro.webm" type="video/webm" />
              </video>
            </div>
            <Picture
              class="-translate-y-1/2 -translate-x-1/2 pointer-events-none absolute top-1/2 left-1/2 w-[60%] min-w-[200px] max-w-[320px] shadow-2xl shadow-green-800"
              width={831}
              height={1490}
              densities={"x1"}
              sizes="640px"
              src="/storage/assets/static/phone-frame.png"
              imgAttrs={{ ariaHidden: true }}
            />
          </div>
        </div>
        <div class="pointer-events-none relative h-full pt-28 text-white lg:pt-36">
          <MobileSafeArea class="flex h-full flex-col justify-between">
            <div class="content pb-5">
              <div class="col-span-6 xl:col-span-5">
                <h1 class="pr-4 text-4xl xl:text-6xl">{t("homepage.app.headline")}</h1>
              </div>
            </div>
            <div class="content pb-32 lg:pb-20">
              <div class="pointer-events-auto col-span-6 xl:col-span-5">
                <div class="pr-4">
                  <p class="text-xl">{t("homepage.app.copy")}</p>
                  <p class="mt-12 text-xs">{t("homepage.app.download")}</p>
                  <div class="mt-5 flex gap-2">
                    <Link
                      href={t("homepage.app.appstore.url")}
                      label={t("homepage.app.appstore.ariaLabel")}
                    >
                      <img class="w-28" src={appstore} alt={t("header.logoAlt")} />
                    </Link>
                    <Link
                      href={t("homepage.app.playstore.url")}
                      label={t("homepage.app.playstore.ariaLabel")}
                    >
                      <img class="w-28" src={playstore} alt={t("header.logoAlt")} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </MobileSafeArea>
        </div>
      </>
    );
  },
  {
    name: "FullscreenAppTeaser",
  },
);
