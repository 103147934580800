import { usePersistentState } from "package:/composables/usePersistentState";
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useFlags,
  useIsDesktop,
} from "#imports";

export const Intro = defineComponent(() => {
  const loaded = ref(false);
  const videoRef = ref<HTMLVideoElement>();
  const isDesktop = useIsDesktop();
  const showIntro = usePersistentState("intro", useFlags().intro);

  function skipIntro() {
    showIntro.value = false;
  }

  // fix isue with video not autoplay on native ios when capcacitor cookie plugin is enabled
  function onPause() {
    if (videoRef.value) {
      videoRef.value.play();
    }
  }

  onMounted(() => {
    loaded.value = true;
    // fix isue with video not autoplay on native ios when capcacitor cookie plugin is enabled
    nextTick(() => {
      if (videoRef.value) {
        setTimeout(() => {
          videoRef.value?.play();
        }, 300);
      }
    });
  });

  return () => {
    return (
      !isDesktop.value &&
      showIntro.value &&
      loaded.value && (
        <div class="fixed top-0 left-0 z-50 h-full w-full">
          <video
            ref={videoRef}
            class="h-full w-full object-cover"
            autoplay
            muted
            playsinline
            onClick={skipIntro}
            onEnded={skipIntro}
            // fix isue with video not autoplay on native ios when capcacitor cookie plugin is enabled
            onPause={onPause}
          >
            <source src="intro/intro.mov" type="video/quicktime" />
            <source src="intro/intro.webm" type="video/webm" />
          </video>
        </div>
      )
    );
  };
});
