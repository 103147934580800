import { Dynamic } from "package:/components/elements/Dynamic";
import { LoadingError } from "package:/components/elements/LoadingError";
import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { FloatingActionButtons } from "package:/components/modules/FloatingActionButtons";
import { Footer } from "package:/components/modules/Footer";
import { FullscreenAppTeaser } from "package:/components/modules/FullscreenAppTeaser";
import { FullscreenAreaTeaser } from "package:/components/modules/FullscreenAreaTeaser";
import { FullscreenTeaser } from "package:/components/modules/FullscreenTeaser";
import { FullscreenTeaserPositionIndicator } from "package:/components/modules/FullscreenTeaserPositionIndicator";
import { Intro } from "package:/components/modules/Intro";
import { PoiTabSlider } from "package:/components/modules/PoiTabSlider";
import { ProjectStatus } from "package:/components/modules/ProjectStatus";
import { useApi } from "package:/composables/useApi.js";
import { areasIndexFiltered } from "package:/utils";
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useIsDesktop,
  useMeta,
  useTranslations,
  useEnv,
} from "#imports";

export const Home = defineComponent(
  async () => {
    const isDesktop = useIsDesktop();
    const t = useTranslations();
    const env = useEnv();

    const snapActive = ref(false);

    const sections = ref<HTMLElement[]>([]);
    const sectionsDisableSnap = ref<HTMLElement[]>([]);
    const scrollWrapper = ref<HTMLElement>();
    const activeSectionIndex = ref(0);
    const scrollDirectionUp = ref(false);

    const touchDelta = { x: 0, y: 0 };

    let lastScrollPosition = 0;

    const poiData = useApi((api) => api.poi.poisIndex(null, null, "1"));
    const apiData = useApi((api) => areasIndexFiltered(api.area.areasIndex()));

    useMeta();

    const onScroll = (event) => {
      const scrollPosition = event.currentTarget.scrollTop;
      scrollDirectionUp.value = scrollPosition < lastScrollPosition;
      lastScrollPosition = scrollPosition;
      if (scrollDirectionUp.value) {
        setTimeout(() => {
          scrollDirectionUp.value = false;
        }, 500);
      }
    };

    const onTouchStart = (event) => {
      touchDelta.x = event.touches[0].pageX;
      touchDelta.y = event.touches[0].pageY;
    };

    const onTouchMove = (event) => {
      const offsetX = touchDelta.x - event.touches[0].pageX;
      const offsetY = touchDelta.y - event.touches[0].pageY;
      if (Math.abs(offsetY) < 10 || Math.abs(offsetX) > 15) {
        event.preventDefault();
      }
    };

    onMounted(() => {
      if (scrollWrapper.value) {
        scrollWrapper.value.addEventListener("scroll", onScroll);
        // fix jumping on horizontal scroll poiTabSlide on ios
        scrollWrapper.value.addEventListener("touchstart", onTouchStart);
        scrollWrapper.value.addEventListener("touchmove", onTouchMove);
      }
    });

    onUnmounted(() => {
      if (scrollWrapper.value) {
        scrollWrapper.value.removeEventListener("scroll", onScroll);
        scrollWrapper.value.removeEventListener("touchstart", onTouchStart);
        scrollWrapper.value.removeEventListener("touchmove", onTouchMove);
      }
    });

    let observer: IntersectionObserver | null = null;

    const updateSectionIntersectionObserver = () => {
      if (sections.value.length > 0) {
        observer?.disconnect();
        observer = null;
        observer = new IntersectionObserver(
          (entries) => {
            for (const entry of entries) {
              if (entry.isIntersecting) {
                activeSectionIndex.value = sections.value.indexOf(
                  entry.target as HTMLElement,
                );
                snapActive.value = !(
                  sectionsDisableSnap.value.indexOf(entry.target as HTMLElement) !== -1
                );
              } else if (
                sectionsDisableSnap.value.indexOf(entry.target as HTMLElement) !== -1
              ) {
                snapActive.value = true;
              }
              if (!snapActive.value && scrollDirectionUp.value) {
                snapActive.value = false;
              }
            }
          },
          { threshold: 0.01 },
        );

        for (const section of sections.value) {
          if (section) {
            observer.observe(section);
          }
        }
      }
    };

    // home is required to be loaded sync, because of layout shift and scroll position
    await apiData.suspense();

    return () => {
      let sectionIndex = 0;
      updateSectionIntersectionObserver();

      return (
        <div>
          <div
            class={[
              snapActive.value ? "snap-y snap-mandatory" : "",
              "h-dvh w-screen overflow-y-scroll",
              "narrow-xs:h-full narrow-xs:snap-none narrow-xs:overflow-auto",
            ]}
            ref={scrollWrapper}
          >
            {isDesktop.value && (
              <section
                class="relative snap-start"
                ref={
                  ((el: HTMLElement) => {
                    sectionIndex = 0;
                    sections.value[sectionIndex] = el;
                    sectionIndex++;
                  }) as any
                }
              >
                <FullscreenTeaser>
                  <FullscreenAppTeaser />
                </FullscreenTeaser>
              </section>
            )}

            {apiData.data.value?.data.map((area, index, arr) => {
              return (
                <section
                  class="relative snap-start"
                  key={`home-section-${area.slug}`}
                  ref={
                    ((el: HTMLElement) => {
                      sections.value[sectionIndex] = el;
                      sectionIndex++;
                    }) as any
                  }
                >
                  <FullscreenTeaser
                    backgroundSrc={area?.image?.url}
                    mobileBackgroundSrc={area?.image_mobile?.url}
                  >
                    <FullscreenAreaTeaser
                      area={area}
                      index={index + 1}
                      total={arr.length}
                    />
                  </FullscreenTeaser>
                </section>
              );
            })}

            <section
              class="relative snap-start"
              ref={
                ((el: HTMLElement) => {
                  sections.value[sectionIndex] = el;
                  sectionIndex++;
                }) as any
              }
            >
              <FullscreenTeaser class="bg-green-800">
                <div class="h-full pt-16 text-white lg:pt-36">
                  <MobileSafeArea class="flex h-full flex-col justify-between">
                    <div class="content">
                      <div class="col-span-12">
                        <p class="mb-1 text-xs uppercase">
                          {t("homepage.highlights.kicker")}
                        </p>
                        <h2 class="text-4xl lg:text-6xl">
                          {t("homepage.highlights.headline")}
                        </h2>
                      </div>
                    </div>
                    <div class="content mt-2 w-full pb-24 lg:pb-20">
                      <div class="col-span-12">
                        <Dynamic data={poiData}>
                          <PoiTabSlider
                            categorized={true}
                            variant="dark"
                            pois={poiData.data.value?.data}
                            reducedHeight={true}
                          />
                        </Dynamic>
                      </div>
                    </div>
                  </MobileSafeArea>
                </div>
              </FullscreenTeaser>
            </section>

            <section
              class="relative snap-start"
              ref={
                ((el: HTMLElement) => {
                  sections.value[sectionIndex] = el;
                  sectionsDisableSnap.value[sectionIndex] = el;
                  sectionIndex++;
                }) as any
              }
            >
              <ProjectStatus />

              {isDesktop.value && <Footer />}
            </section>
          </div>

          {isDesktop.value && (
            <FullscreenTeaserPositionIndicator
              activeIndex={activeSectionIndex.value}
              total={sectionIndex}
            />
          )}

          {!isDesktop.value && <FloatingActionButtons class="z-30" />}

          <Intro />
        </div>
      );
    };
  },
  {
    name: "Home",
  },
);
