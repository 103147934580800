import { Icon } from "package:/components/elements/Icon";
import { Link } from "package:/components/elements/Link";
import type { RouteLocationNamedRaw } from "vue-router";
import { defineComponent } from "#imports";

interface Props {
  label: string;
  to: RouteLocationNamedRaw;
}

export const CardLink = defineComponent(
  (props: Props) => {
    return () => (
      <Link
        class="relative block h-full w-[var(--width-card-link)] rounded bg-green-800 p-5 text-white focus-visible:outline-offset-0"
        to={props.to}
      >
        <div class="group flex h-full flex-col justify-between">
          <h3 class="font-regular">{props.label}</h3>
          <Icon
            name="arrow-right"
            class="text-[1.125rem] group-hover:text-purple-400"
            aria-hidden="true"
          />
        </div>
      </Link>
    );
  },
  {
    name: "CardLink",
    props: ["to", "label"],
  },
);
