import { buttonVariants } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import { Link } from "package:/components/elements/Link";
import { defineComponent, useLanguage, useTranslations } from "#imports";
import { MobileSafeArea } from "../elements/MobileSafeArea";

export const FloatingActionButtons = defineComponent(() => {
  const t = useTranslations();
  const lang = useLanguage();

  return () => (
    <div class="-translate-x-1/2 fixed bottom-6 left-1/2">
      <MobileSafeArea>
        <Link
          class={buttonVariants.big}
          to={{
            name: "lang-map",
            params: {
              lang: lang.value,
            },
            query: {
              view: null,
            },
          }}
        >
          <Icon name="map" aria-hidden="true" />
          <span class="text-base">{t("button.map")}</span>
        </Link>
      </MobileSafeArea>
    </div>
  );
});
