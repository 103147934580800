import { defineComponent } from "#imports";

export const FullscreenTeaserPositionIndicator = defineComponent(
  (props: {
    activeIndex: number;
    total: number;
  }) => {
    return () => (
      <ul class="-translate-y-1/2 fixed top-1/2 left-8 z-1 space-y-4">
        {Array.from({ length: props.total }).map((_, index) => (
          <li
            key={`home-position-indicator-${index}`}
            class={[
              "h-[2px] w-[6px] rounded-sm bg-white",
              index !== props.activeIndex ? "opacity-40" : "",
            ]}
          />
        ))}
      </ul>
    );
  },
  {
    name: "FullscreenTeaserPositionIndicator",
    props: ["activeIndex", "total"],
  },
);
