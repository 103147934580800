import { Accordion } from "package:/components/elements/Accordion";
import { Dynamic } from "package:/components/elements/Dynamic";
import { LoadingError } from "package:/components/elements/LoadingError";
import { Picture } from "package:/components/elements/Picture";
import { RichText } from "package:/components/elements/RichText";
import { useApi } from "package:/composables/useApi.js";
import type { ClassNameValue } from "tailwind-merge";
import { computed, defineComponent, useTranslations } from "#imports";

const ProjectProgressBar = (props: {
  class?: ClassNameValue;
  progress: number;
  total?: number;
  label?: string;
}) => {
  const total = props.total ?? 14;
  return (
    <div
      class={`relative flex h-[4.375rem] items-center overflow-hidden rounded-md bg-green-500 text-white before:absolute before:block before:h-full before:w-[var(--progress)] before:bg-purple-400 before:content-['']`}
      style={{ "--progress": `${(100 / total) * props.progress}%` }}
    >
      <div class="relative z-1 flex items-center gap-3 px-5 uppercase">
        <span class="text-3xl">{props.progress}</span>
        {props.label && <span class="text-xs">{props.label}</span>}
      </div>
    </div>
  );
};

export const ProjectStatus = defineComponent(
  () => {
    const t = useTranslations();
    const progressData = useApi(
      (api) => api.areaProgress.progressIndex(),
      "status",
      true,
    );

    const completedItems = computed(() =>
      progressData.data.value?.data.filter((item) => item.state === "completed"),
    );
    const constructionItems = computed(() =>
      progressData.data.value?.data.filter((item) => item.state === "construction"),
    );
    const planningItems = computed(() =>
      progressData.data.value?.data.filter((item) => item.state === "planning"),
    );

    const lastUpdateDate = computed(() => {
      let latest = new Date(0);
      for (const item of progressData.data.value?.data || []) {
        const date = new Date(item.updated_at);
        if (date > latest) {
          latest = date;
        }
      }
      return latest.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
      });
    });

    return () => {
      return (
        <div class="flex h-full w-full flex-col justify-between bg-green-800 pt-16 pb-28 text-white lg:pt-36">
          <Dynamic data={progressData} error={() => <LoadingError />}>
            <div class="content">
              <div class="col-span-12 lg:col-span-10 lg:col-start-2">
                <p class="mb-1 text-xs uppercase">{t("homepage.projectStatus.kicker")}</p>
                <h2 class="text-4xl lg:text-6xl">
                  {t("homepage.projectStatus.headline")}
                </h2>
                <div class="mt-28 space-y-0.5">
                  <ProjectProgressBar
                    label={t("homepage.projectStatus.operation")}
                    progress={completedItems.value?.length || 0}
                  />
                  <ProjectProgressBar
                    label={t("homepage.projectStatus.construction")}
                    progress={constructionItems.value?.length || 0}
                  />
                  <ProjectProgressBar
                    label={t("homepage.projectStatus.planing")}
                    progress={planningItems.value?.length || 0}
                  />
                </div>
                <div class="mt-4 mb-10 text-green-200 text-xs uppercase">
                  {t("homepage.projectStatus.date", {
                    date: lastUpdateDate.value,
                  })}
                </div>
                <RichText html={t("homepage.projectStatus.copy")} />
              </div>
            </div>
            <div class="content max-lg:px-0">
              <div class="col-span-12 lg:col-span-10 lg:col-start-2">
                <div class="mt-20">
                  <h3 class="text-xl max-lg:px-5">
                    {t("homepage.projectStatus.construction.headline")}
                  </h3>
                  <div class="space-y-0.5">
                    <Picture
                      class="relative mt-8 block overflow-hidden md:h-0 md:pb-[40%]"
                      src="/storage/assets/static/project-status/construction-1.png"
                      width={1440}
                      height={900}
                      sizes="1024px xxl:1440px"
                      imgClass="md:absolute top-0 left-0 w-full h-full"
                      alt={t("homepage.projectStatus.construction.headline")}
                    />
                    <Accordion>
                      {constructionItems.value?.map((item) => {
                        return (
                          <Accordion.Item
                            title={item.title ?? ""}
                            subtitle={item.state_until ?? ""}
                            key={`project-status-${item.title}`}
                          >
                            {item.copy && <RichText html={item.copy} />}
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </div>
                </div>
                <div class="mt-20">
                  <h3 class="text-xl max-lg:px-5">
                    {t("homepage.projectStatus.planning.headline")}
                  </h3>
                  <div class="space-y-0.5">
                    <Picture
                      class="relative mt-8 block overflow-hidden md:h-0 md:pb-[40%]"
                      src="/storage/assets/static/project-status/planning-1.png"
                      width={1440}
                      height={900}
                      sizes="1024px xxl:1440px"
                      imgClass="md:absolute top-0 left-0 w-full h-full"
                      alt={t("homepage.projectStatus.planning.headline")}
                    />
                    <Accordion>
                      {planningItems.value?.map((item) => {
                        return (
                          <Accordion.Item
                            title={item.title ?? ""}
                            subtitle={item.state_until ?? ""}
                            key={`project-status-${item.title}`}
                          >
                            {item.copy && <RichText html={item.copy} />}
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </Dynamic>
        </div>
      );
    };
  },
  {
    name: "ProjectStatus",
    props: [],
  },
);
