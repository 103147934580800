import { Picture } from "package:/components/elements/Picture";
import type { ClassNameValue } from "tailwind-merge";
import { defineComponent, useIsDesktop } from "#imports";

interface Props {
  class?: ClassNameValue;
  backgroundSrc?: string;
  mobileBackgroundSrc?: string;
}

export const FullscreenTeaser = defineComponent(
  (props: Props, { slots }) => {
    const isDesktop = useIsDesktop();

    return () => (
      <div
        class={[
          "h-dvh w-full overflow-hidden bg-green-500 narrow-xs:h-full",
          props.class,
        ]}
      >
        {props.backgroundSrc && (
          <Picture
            class="absolute h-full w-full after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-black after:opacity-50 after:content-['']"
            width={!isDesktop.value ? 940 : 1440}
            height={!isDesktop.value ? 1440 : 940}
            sizes="1024px lg:1280px xxl:1920px"
            densities={"x1"}
            src={
              !isDesktop.value && props.mobileBackgroundSrc
                ? props.mobileBackgroundSrc
                : props.backgroundSrc
            }
            imgClass="w-full h-full"
            imgAttrs={{ ariaHidden: true }}
          />
        )}
        <div class="relative z-1 h-full">{slots.default?.()}</div>
      </div>
    );
  },
  {
    name: "FullscreenTeaser",
    props: ["class", "backgroundSrc", "mobileBackgroundSrc"],
  },
);
